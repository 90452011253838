<template>
  <div>
    <div class="account-management-manu operation margin-top-bottom-twenty border-radius-6 box-shadow-light-grey ">
      <el-menu
        class=""
        :default-active="activeSubMenu"
        mode="horizontal"
        router
      >
        <el-menu-item
          class="font-eighteen height-fiftyTwo margin-right-23"
          v-for="item in accountMenu"
          :key="item.path"
          :index="item.path"
        >
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div>
      <router-view :params="customParams"></router-view>
    </div>
  </div>
</template>

<script>
import { accountMenu } from '@/assets/js/nav.js'
export default {
  name: 'Account',
  props: {
    params: Object
  },
  data () {
    return {
      tabName: 'account',
      customParams: {},
      activeSubMenu: this.$route.meta.submenu,
      accountMenu
    }
  },
  watch: {
    $route: {
      handler (newV) {
        this.activeSubMenu = newV.meta.submenu || newV.path
      },
      immediate: true
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    init () {
      this.customParams = { ...this.params }
    },
    handleTabClick (tab) {
      if (tab === 'account') {
        this.$router.push({
          name: 'PlatformAccount'
        })
      }
      if (tab === 'channel') {
        this.$router.push({
          name: 'ChannelList'
        })
      }
      if (tab === 'login') {
        this.$router.push({
          name: 'AccountLogin'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-menu-item.is-active {
  border-right: 0;
}
.el-menu {
  height: 52px;
  line-height: 52px;
}
.el-menu-item {
  height: 100%;
}

.account-management-manu{
  box-sizing: border-box;
  padding:0 5px;
  background:#fff;
}
.operation {
  /deep/ .el-menu-item,
  .el-submenu__title {
    height: 34px;
  }
  /deep/ .el-menu {
    height: 52px !important;
    display: flex;
    align-items: center;
    .el-menu-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
